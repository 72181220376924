import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { TokenTable } from '~/components/TokensTable/TokenTable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <p>{`Vi kan anbefale å bruke vår `}<a parentName="p" {...{
        "href": "/komponenter/ressurser/typography"
      }}>{`Typografipakke`}</a>{` for konsistent hierarki for typografi.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@use '@entur/tokens/dist/styles.scss';

.button {
    font-weight: $font-weights-body;
    font-size: $font-sizes-extra-small;
    line-height: $line-heights-extra-small;
}
`}</code></pre>
    <h2 {...{
      "id": "tekstvekting-font-weight"
    }}>{`Tekstvekting (font-weight)`}</h2>
    <p>{`Tekstvekting har verdier for tykkelsen eller `}<em parentName="p">{`boldness`}</em>{` til en font. Ved å definere fontvekter som tokens kan man bygge forskjellige typografiske stiler og hierarkier. Til eksempel har man `}<inlineCode parentName="p">{`$font-weights-body`}</inlineCode>{` for brødtekst (body er brødtekst på engelsk) og `}<inlineCode parentName="p">{`$font-weights-heading`}</inlineCode>{` til overskrifter.`}</p>
    <TokenTable tokenKey="fontWeights" example={({
      value
    }) => <div style={{
      fontWeight: value
    }}>ABC abc</div>} mdxType="TokenTable" />
    <h2 {...{
      "id": "tekststørrelser-size"
    }}>{`Tekststørrelser (size)`}</h2>
    <p>{`Tekststørrelser inneholder fontstørrelser. Tokenssettet har verdier fra minste tekst small text opp til største tekst heading 1. Bruk av størrelsetokens, som `}<inlineCode parentName="p">{`$font-sizes-small`}</inlineCode>{`, gjør det enklere å skalere tekst basert på eksempelvis brukerens enhet. Da kan tokenen peke på ulike verdier for bruk på mobil eller PC. Den semantiske navngivningen gjør det også enklere å opprettholde visuelt hierarki i tekst.`}</p>
    <TokenTable tokenKey="fontSizes" example={({
      value
    }) => <div style={{
      fontSize: value
    }}>ABC abc</div>} mdxType="TokenTable" />
    <h2 {...{
      "id": "linjehøyder-line-height"
    }}>{`Linjehøyder (line-height)`}</h2>
    <p>{`Linjehøyder bestemmer avstanden mellom linjene i tekstblokker. Å ha klart definerte linjehøyder bidrar til å forbedre lesbarheten av teksten. Linjehøyder kan også tilpasses forskjellige tekststiler og størrelser for å sikre at linjeavstanden passer godt sammen med den valgte fonten og layouten.`}</p>
    <TokenTable tokenKey="lineHeights" mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      